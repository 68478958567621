const beeping = require('assets/images/requests/beeping.png');
const blanket = require('assets/images/requests/blanket.png');
const chairadjustment = require('assets/images/requests/chairadjustment.png');
const cleaning = require('assets/images/requests/cleaning.png');
const codeblue = require('assets/images/requests/codeblue.png');
const emergency = require('assets/images/requests/emergency.png');
const ice = require('assets/images/requests/ice.png');
const ivpain = require('assets/images/requests/ivpain.png');
const assistance = require('assets/images/requests/assistance.png');
const pain = require('assets/images/requests/pain.png');
const snack = require('assets/images/requests/snack.png');
const toilet = require('assets/images/requests/toilet.png');
const water = require('assets/images/requests/water.png');
const blackout_shade = require('assets/icons/blackout_shade.png');
const lights = require('assets/icons/lights.png');
const sun_shade = require('assets/icons/sun_shade.png');
import room from 'assets/icons/room.svg';
const relax = require('assets/icons/relax.png');
const energize = require('assets/icons/energize.png');
const off = require('assets/icons/off.png');
const fan = require('assets/icons/fan.png');
const light_1 = require('assets/icons/light_1.png');
const light_4 = require('assets/icons/light_4.png');

const cleaner = (key, value) =>
    // See re prototype pollution: https://book.hacktricks.xyz/pentesting-web/deserialization/nodejs-proto-prototype-pollution
    key === '__proto__' || key === 'constructor' ? undefined : value;

export function decodeUrlSearchParams(url) {
    const params = new URLSearchParams(decodeURIComponent(url));
    const decoded = Object.create(null);
    for (const [k, v] of params.entries()) {
        try {
            decoded[k] = JSON.parse(v, cleaner);
        } catch {
            decoded[k] = v;
        }
    }
    return decoded;
}

export function getQuery() {
    let obj = {};
    let vars = window.location.search.substring(1).split('&');
    for (var i = 0; i < vars.length; i++) {
        let [key, value] = vars[i].split('=');
        obj[key] = value;
    }
    return obj;
}

export const getDNS = () => {
    const dns = sessionStorage.getItem('dns');
    return dns || '';
};

// clears info needed to access web page(byod/admin)
export const clearAccessInfo = () => {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('dns');
};

export const naturalSort = (a, b, key) => {
    return a[key].localeCompare(
        b[key],
        navigator.languages[0] || navigator.language,
        {
            numeric: true,
            ignorePunctuation: true
        }
    );
};

export const capitalize = (str) => {
    let words = str.split(' ');
    return words
        .map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        })
        .join(' ');
};

export const getTitle = (request) => {
    switch (request) {
        case 'water':
            return 'Water';
        case 'restroom':
            return 'Bathroom';
        case 'snack':
            return 'Food';
        case 'icechips':
            return 'Ice Chips';
        case 'ice':
            return 'Ice';
        case 'beeping':
            return 'IV Beeping';
        case 'pain':
            return 'Pain';
        case 'toilet':
            return 'Toilet';
        case 'blanket':
            return 'Blanket';
        case 'cleaning':
            return 'Cleaning';
        case 'assistance':
            return 'Assistance';
        case 'codeblue':
            return 'Code Blue';
        case 'ivpain':
            return 'IV Discomfort';
        case 'chairadjustment':
            return 'Chair Adjustment';
        case 'emergency':
            return 'Emergency Call';

        default:
            return;
    }
};

export const DeviceTypes = {
    DeviceBay: 'DeviceBay',
    VideoBay: 'VideoBay'
};

export const DSDChannelTypes = {
    READING_LIGHTS: 'reading_lights',
    ROOM_LIGHTS: 'room_lights',
    BLACKOUT_SHADE: 'blackout_shade',
    SUN_SHADE: 'sun_shade'
};



// cna 20221220 Settings for Room Control for Mate Lighting - Houston Methodist
export const ROOM_CONTROLS_STANDARD = [
    {
        label: 'Reading Lights',
        value: 'BUTTON_PRESSED_ReadLIGHT-L1',
        image: 'light_4',
        desc: 'TapOnOff'
    },
    {
        label: 'Room Lights',
        value: 'BUTTON_PRESSED_RoomLIGHT-L2',
        image: 'light_1',
        desc: 'TapOnOff'
    },
    {
        label: 'Blackout Shade',
        value: 'BUTTON_PRESSED_ShadeDOWN',
        image: 'blackout_shade',
        desc: 'TapRaiseLower'
    },
    {
        label: 'Sun Shade',
        value: 'BUTTON_PRESSED_ShadeUP',
        image: 'sun_shade',
        desc: 'TapRaiseLower'
    }
];

// standard room control settings 
export const ROOM_CONTROLS_MATE_LIGHTING = [
    {
        label: 'Room',
        value: 'BUTTON_PRESSED_ReadLIGHT-L1',
        image: 'room'
    },
    {
        label: 'Relax',
        value: 'BUTTON_PRESSED_RoomLIGHT-L2',
        image: 'relax'
    },
    {
        label: 'Energize',
        value: 'BUTTON_PRESSED_ShadeDOWN',
        image: 'energize'
    },
    {
        label: 'Off',
        value: 'BUTTON_PRESSED_ShadeUP',
        image: 'off'
    }
];

export const REQUEST_TYPES = [
    'assistance',
    'beeping',
    'blanket',
    'chairadjustment',
    'cleaning',
    'codeblue',
    'emergency',
    'ice',
    'ivpain',
    'pain',
    'snack',
    'toilet',
    'water'
];

export const REQUEST_TYPES_MOBILE = [
    'pain',
    'toilet',
    'water',
    'beeping',
    'blanket',
    'chairadjustment',
    'cleaning',
    'emergency',
    'ice',
    'ivpain',
    'snack'
];

export const REQUEST_TYPES_LEON = [
    'ivpain',
    'chairadjustment',
    'water',
    'restroom',
    'snack',
    'assistance',
    'codeblue',
    'emergency'
];

export const images = {
    water: 'water',
    bathroom: 'bathroom',
    food: 'food',
    iv_pain: 'iv_pain',
    tv_issues: 'tv_issues',
    chair_adjust: 'chair',
    assistance: 'assistance-orange',
    // assistanceOrange: "assistance-orange",
    admissions: 'admissions',
    check: 'check',
    requests: 'requests',
    stopwatch: 'stopwatch',
    code_blue: 'code_blue',
    emergency_call: 'emergency'
};

export const toMMSS = (time) => {
    var sec_num = parseInt(time / 1000);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    return (hours > 0 ? hours + 'h ' : '') + minutes + 'm ' + seconds + 's';
};

export const getImage = (request) => {
    if (request === 'beeping') {
        return beeping;
    } else if (request === 'blanket') {
        return blanket;
    } else if (request === 'chairadjustment') {
        return chairadjustment;
    } else if (request === 'cleaning') {
        return cleaning;
    } else if (request === 'codeblue') {
        return codeblue;
    } else if (request === 'emergency') {
        return emergency;
    } else if (request === 'ice') {
        return ice;
    } else if (request === 'ivpain') {
        return ivpain;
    } else if (request === 'assistance') {
        return assistance;
    } else if (request === 'pain') {
        return pain;
    } else if (request === 'snack') {
        return snack;
    } else if (request === 'toilet') {
        return toilet;
    } else if (request === 'water') {
        return water;
    } else if (request === 'blackout_shade') {
        return blackout_shade;
    } else if (request === 'lights') {
        return lights;
    } else if (request === 'sun_shade') {
        return sun_shade;
    } else if (request === 'room') {
        return room;
    } else if (request === 'relax') {
        return relax;
    } else if (request === 'energize') {
        return energize;
    } else if (request === 'off') {
        return off;
    } else if (request === 'fan') {
        return fan;
    } else if (request === 'light_4') {
        return light_4;
    } else if (request === 'light_1') {
        return light_1;
    } else {
        null;
    }
};

export const capitalizingFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
